<template>
  <b-overlay
    variant="light"
    :show="loading"
    spinner-variant="primary"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <b-card>

      <b-col cols="12">
        <h4>
          <strong>Pengaturan Rekening Bank</strong>
        </h4>
      </b-col>

      <b-col
        v-for="(data, index) in formRekening"
        :key="index + 1"
        md="10"
        class="border ml-1 mt-2 p-1"
      >

        <b-row class="d-flex align-items-center mb-1">
          <b-col>
            <h5>
              <strong>Rekening {{ index+1 }}</strong>
            </h5>
          </b-col>
        </b-row>

        <validation-observer ref="formRules">
          <b-form>
            <b-row>
              <b-col cols="10">
                <b-form-group
                  label="Nama Bank"
                  label-cols-md="3"
                >
                  <div>
                    <b-form-input
                      v-model="data.bank_name"
                      disabled
                    />
                  </div>
                </b-form-group>
              </b-col>

              <b-col cols="10">
                <b-form-group
                  label="No Rekening"
                  label-cols-md="3"
                >
                  <div>
                    <b-form-input
                      v-model="data.account_no"
                      disabled
                    />
                  </div>
                </b-form-group>
              </b-col>

              <b-col cols="10">
                <b-form-group
                  label="Nama"
                  label-cols-md="3"
                >
                  <div>
                    <b-form-input
                      v-model="data.account_name"
                      disabled
                    />
                  </div>
                </b-form-group>
              </b-col>

            </b-row>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- Add Rekening -->
      <b-col
        v-if="fieldActionAddRekening === true"
        md="10"
        class="border ml-1 mt-2 p-1"
      >

        <b-row class="d-flex align-items-center mb-1">
          <b-col>
            <h5>
              <strong>Tambah Rekening</strong>
            </h5>
          </b-col>
        </b-row>

        <validation-observer ref="formRulesAdd">
          <b-form @submit.prevent="checkValidBank || checkBank()">
            <b-row>

              <b-col cols="10">
                <b-form-group
                  label="Nama Bank"
                  label-cols-md="3"
                >
                  <validation-provider
                    #default="{errors}"
                    name="Nama Bank"
                    rules="required"
                  >
                    <v-select
                      v-model="fieldAddBankName"
                      label="name"
                      :reduce="option => option.code"
                      :options="banks"
                      :filterable="true"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ketik untuk mencari..."
                      @input="changeFieldAddBank"
                      @close="$refs.bankAccount.focus()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="validateFieldAddBankName === true"
                      class="text-danger"
                    >*Pilih Bank dulu ya</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="10">
                <b-form-group
                  label="No Rekening"
                  label-cols-md="3"
                >
                  <validation-provider
                    #default="{errors}"
                    name="No Rekening"
                    rules="required"
                  >
                    <div class="d-block d-sm-flex">
                      <b-form-input
                        ref="bankAccount"
                        v-model="fieldAddAccountNo"
                        placeholder="Masukkan Nomor Rekening"
                        :state="errors.length > 5 ? false:null"
                        maxlength="20"
                        @keypress="isNumber($event)"
                        @paste.prevent="AccountBankNo"
                        @input="changeFieldAddBank"
                      />
                      <b-button
                        :variant="fieldAddAccountName !== '' || ValidateAccountName ? 'secondary' : 'primary'"
                        :disabled="fieldAddAccountName !== '' || ValidateAccountName"
                        class="ml-1 cekRekening"
                        @click="getAccount"
                      >
                        <b-spinner
                          v-if="ValidateAccountName"
                          variant="light"
                          style="width: 23px; height: 23px"
                        />
                        <span v-else>Cek Rekening</span>
                      </b-button>
                    </div>
                    <small
                      v-if="validateFieldAddAccountNo"
                      class="text-danger"
                    >No Rekening harus diisi </small>
                    <small class="text-danger">{{ validateLength }} </small>
                    <small
                      v-if="messageSameNoBank !== ''"
                      class="text-danger"
                    >{{ messageSameNoBank }}, </small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                v-if="isValidateAccountName"
                cols="10"
              >
                <b-form-group
                  label-cols-md="3"
                >
                  <div class="d-flex">
                    <div
                      v-if="getValidateAccountName"
                      class="validate-green py-[5px] px-[15px] d-flex"
                    >
                      <b-img
                        rounded="circle"
                        class="bg-[#34A770] mr-1 icon-validate"
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/Checklist.svg"
                      />
                      <span class="align-self-center text-black message-account-name">Nomor Rekening berhasil ditemukan</span>
                    </div>
                    <div
                      v-else
                      class="validate-red py-[5px] px-[15px] d-flex"
                    >
                      <b-img
                        rounded="circle"
                        class="bg-[#FFF2E2] mr-1 icon-validate"
                        src="https://storage.googleapis.com/komerce/assets/icons/danger-yellow.svg"
                      />
                      <span class="align-self-center text-black message-account-name">Nomor Rekening salah/tidak ditemukan</span>
                    </div>
                  </div>
                </b-form-group>
              </b-col>

              <b-col cols="10">
                <b-form-group
                  label="Nama"
                  label-cols-md="3"
                >
                  <validation-provider
                    #default="{errors}"
                    name="Nama"
                    rules="required"
                  >
                    <div class="d-flex">
                      <b-form-input
                        v-model="fieldAddAccountName"
                        placeholder="Nama akan otomatis muncul"
                        class="pr-2"
                        :state="errors.length > 0 ? false:null"
                        disabled
                      />
                    </div>
                    <small
                      v-if="messageSameNameBank !== ''"
                      class="text-danger"
                    >{{ messageSameNameBank }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                md="12"
                class="d-flex justify-content-end mt-1 pb-1"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="cancelAddRekening"
                >
                  Batal
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="submit"
                  :variant="checkValidBank ? 'secondary' : 'primary'"
                  class="mr-1"
                  :disabled="checkValidBank"
                >
                  <b-spinner
                    v-if="loadingSubmit"
                    variant="light"
                    small
                  />
                  Simpan
                </b-button>
              </b-col>

            </b-row>
          </b-form>
        </validation-observer>
      </b-col>

      <b-col
        v-if="formRekening.length < 6 && !fieldActionAddRekening"
        md="10"
        class="ml-1 mt-1"
      >
        <div class="demo-inline-spacing">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            block
            variant="outline-primary"
            @click="addRekening"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              style="display: inline-block"
            />
            <span class="align-middle">Tambahkan Rekening</span>
          </b-button>
        </div>
      </b-col>
    </b-card>

    <!-- Modal Validate Profile -->
    <b-modal
      ref="modal-validate-profile"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <div class="modal-add-pickup-popup-success">
        <b-row class="justify-content-center pt-2 mb-2">
          <img src="@/assets/images/icons/warning.svg">
        </b-row>
        <b-row class="text-center mb-2 px-5">
          <h4 class="text-black">
            <strong>
              Untuk melanjutkan, silahkan lengkapi Profil
              Anda terlebih dahulu
            </strong>
          </h4>
        </b-row>
        <b-row class="justify-content-center pb-2">
          <b-button
            variant="primary"
            class="btn-icon"
            tag="router-link"
            :to="{ name: $route.meta.routeToProfile }"
          >
            Lengkapi Profile
          </b-button>
        </b-row>
      </div>
    </b-modal>

    <!-- Popup Success Add rekening -->
    <b-modal
      ref="success-add-rekening"
      hide-footer
      hide-header
      centered
    >
      <b-row class="my-2 justify-content-center">
        <div>
          <b-img src="@/assets/images/icons/success.svg" />
        </div>
      </b-row>
      <b-row class="justify-content-center text-center">
        <h4 class="text-black">
          <strong>Rekening Berhasil Ditambahkan</strong>
        </h4>
      </b-row>
      <b-row class="justify-content-center text-center mb-2 mx-2">
        <span class="text-black">
          Selamat! Nomor rekening berhasil ditambahkan di akun kamu
        </span>
      </b-row>
      <b-row class="justify-content-center mb-2">
        <b-button
          class="text-white"
          variant="primary"
          @click="closePopupSuccess"
        >
          Oke
        </b-button>
      </b-row>
    </b-modal>

    <!-- Check Rekening -->
    <b-modal
      ref="popup-check-rekening"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <b-row class="justify-content-end">
        <b-button
          class="btn-icon"
          variant="flat-dark"
          size="sm"
          @click="closeCheckRek"
        >
          <b-img src="@/assets/images/icons/close-circle.svg" />
        </b-button>
      </b-row>

      <b-row class="justify-content-center text-center mb-1">
        <h3 class="text-black">
          <strong>
            Rekeningmu udah terdaftar di akun lain
          </strong>
        </h3>
      </b-row>

      <b-row class="text-[#626262] justify-content-center mb-1">
        <b-col cols="11">
          <span>
            Akun Komerce-mu yang lain masih ada tanggungan
            <br>
            saldo minus yakni;
          </span>
        </b-col>
      </b-row>

      <b-row class="justify-content-center mb-1">
        <b-col cols="11">
          <table class="w-full">
            <tr>
              <th class="px-1 py-50 border__table__check__rek">
                <span class="text-black">Email</span>
              </th>
              <th class="px-1 py-50 border__table__check__rek">
                <span class="text-black">Kewajiban Top Up</span>
              </th>
            </tr>
            <tr
              v-for="(item, index) in banksDataMinus"
              :key="index+1"
            >
              <td class="px-1 py-50 border__table__check__rek">
                <span class="text-primary"><strong>{{ item.email }}</strong></span>
              </td>
              <td class="px-1 py-50 border__table__check__rek">
                <span class="text-primary"><strong>-Rp{{ formatNumber(item.saldo) }},-</strong></span>
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>

      <b-row class="text-[#626262] justify-content-center mb-1">
        <b-col cols="11">
          <span>
            Lunasi dulu buat lanjut tambah rekening.
          </span>
        </b-col>
      </b-row>

      <b-row class="text-[#626262] justify-content-center mb-1">
        <b-col cols="11">
          <span>
            <strong>
              Sertakan juga alasanmu pake akun baru ini :
            </strong>
          </span>
        </b-col>
      </b-row>

      <b-row class="text-[#626262] justify-content-center mb-1">
        <b-col cols="11">
          <b-form-textarea
            v-model="reasonCreateRekening"
            rows="3"
          />
        </b-col>
      </b-row>

      <b-row class="justify-content-center mb-1">
        <b-button
          :class="reasonCreateRekening === '' ? 'cursor-not-allowed' : ''"
          :style="reasonCreateRekening === '' ? 'background: #C2C2C2; min-width: 120px; border-radius: 10px;' : 'background: #F95031!important; min-width: 120px; border-radius: 10px; border: 0!important;'"
          :disabled="reasonCreateRekening === ''"
          @click="submitAccount"
        >
          Submit
        </b-button>
      </b-row>
    </b-modal>

    <ModalNonePIN
      id="modal-none-PIN"
      title="Yuk, buat PIN baru untuk jaga keamanan akun kamu!"
      description="Untuk menambahkan rekening, silakan buat PIN keamanan terlebih dahulu"
      label-confirm-button="Buat PIN"
      label-cancel-button="Batal"
      @on-confirm-button="$router.push({ name: 'kompship-pin' })"
      @on-cancel-button="$router.push({ name: 'dashboard-komship' })"
    />

    <ModalPIN
      id="modal-verify-pin"
      :partner-id="profile.partner_id"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      title="Verifikasi PIN"
      description="Mohon verifikasi identitas kamu dengan masukan PIN"
      label-confirm-button="Konfirmasi"
      label-cancel-button="Batal"
      @on-confirm-button="successVerifyPIN"
      @on-cancel-button="$router.push({ name: 'dashboard-komship' })"
    />

    <ModalMaxChancePIN />

    <ModalReqOTP
      id="req-OTP-phone"
      title="Tambah Rekening"
      :partner-id="profile.partner_id"
      :phone-number="profile.user_phone"
      purpose="rekening"
      @on-confirm-button="requestTypeSelected"
    />

    <ModalVerifyOtp
      id="confirm-otp-phone"
      title="Verifikasi Kode OTP"
      :partner-id="profile.partner_id"
      :phone-number="profile.user_phone"
      :request-type="requestType"
      :token="tokenPhoneVerify"
      :bank-name="fieldAddBankName"
      :account-no="fieldAddAccountNo"
      :account-name="fieldAddAccountName"
      purpose="rekening"
      label-confirm-button="Konfirmasi"
      @on-confirm-button="successVerifyOldPhone"
      @on-cancel-button="$bvModal.show('req-OTP-phone')"
    />
  </b-overlay>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BModal,
  VBModal,
  BOverlay,
  BSpinner,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import useJwt from '@/auth/jwt/useJwt'
import { isNumber } from '@/libs/helpers'
import ModalPIN from '@/views/components/modal/ModalPIN.vue'
import ModalMaxChancePIN from '@/views/components/modal/ModalMaxChancePIN.vue'
import ModalNonePIN from '@/views/components/modal/ModalNonePIN.vue'
import ModalReqOTP from '@/views/components/modal/ModalReqOTP.vue'
import ModalVerifyOtp from '@/views/components/modal/ModalVerifyOTP.vue'
import secureLs from '@/libs/secureLs'

export default {
  components: {
    BRow,
    BCard,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    ValidationObserver,
    ValidationProvider,
    BOverlay,
    BSpinner,
    vSelect,
    ModalNonePIN,
    ModalPIN,
    ModalMaxChancePIN,
    ModalReqOTP,
    ModalVerifyOtp,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      loadingSubmit: false,
      loading: false,
      banks: [],
      required,
      bankName: '',
      accountNo: '',
      accountName: '',
      formRekening: [{ row: '' }],
      fieldActionAddRekening: false,
      // Create Rekening Bank
      fieldAddBankName: '',
      fieldAddAccountNo: '',
      fieldAddAccountName: '',
      phoneUser: '',
      phoneNumber: '',
      validateProfile: [],
      messageErrorPhoneUser: false,
      checkValidBank: true,
      messageSameNameBank: '',
      messageSameNoBank: '',
      banksDataMinus: [],
      reasonCreateRekening: '',
      ValidateAccountName: false,
      accountNameDB: false,
      validateLength: '',
      isNumber,
      getValidateAccountName: false,
      isValidateAccountName: false,
      validateFieldAddBankName: false,
      validateFieldAddAccountNo: false,
      lenghtNoAccount: false,

      requestType: '',
      tokenPhoneVerify: '',
    }
  },
  computed: {
    ...mapState('dashboard', ['profile']),
  },
  mounted() {
    this.showModal()
    this.loadBanks()
    this.getProfile()
  },
  methods: {
    getBank() {
      this.loading = true
      return this.$http_komship.get('v1/bank-account', {
        headers: { Authorization: `Bearer ${useJwt.getToken()}` },
      }).then(response => {
        const { data } = response.data
        this.formRekening = data
        this.loading = false
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Gagal',
            icon: 'AlertCircleIcon',
            text: 'Gagal load data, silahkan refresh halaman!',
            variant: 'danger',
          },
        }, 2000)
        this.loading = false
      })
    },
    showModal() {
      this.loading = true
      this.$http_komship.get('/v1/pin/check', {
        headers: { Authorization: `Bearer ${useJwt.getToken()}` },
      }).then(response => {
        const { data } = response.data
        if (data.is_set) {
          this.checkVerifyPIN()
        } else {
          this.$bvModal.show('modal-none-PIN')
        }
      }).catch(() => {
        this.loading = false
        this.$toast_error({ message: 'Gagal cek pin, silahkan refresh page' })
      })
    },
    checkVerifyPIN() {
      this.$http_new.get('/auth/api/v1/user/secured/verify-pin/attempt-left')
        .then(response => {
          const { data } = response.data
          if (data.attempt_left === 0) {
            this.$bvModal.show('max-chance-PIN')
          } else {
            this.$bvModal.show('modal-verify-pin')
          }
          this.loading = false
        }).catch(() => {
          this.loading = false
          this.$toast_error({ message: 'Gagal cek PIN, silahkan refresh page' })
        })
    },
    successVerifyPIN(value) {
      this.getBank()
    },
    loadBanks() {
      this.$http
        .get('xendit/disbursementbankAvailable')
        .then(({ data }) => {
          const exceptBanks = ['OVO', 'GOPAY', 'DANA', 'SHOPEEPAY', 'LINKAJA']
          this.banks = data.data.filter(bank => !exceptBanks.includes(bank.code))
        })
        .catch(() => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Terjadi Kesalahan',
                text: 'Terjadi kesalahan saat pengambilan data bank',
                variant: 'danger',
                attachment: 'AlertTriangleIcon',
              },
            },
            { timeout: 2500 },
          )
        })
    },
    addRekening() {
      if (this.validateProfile.user_name === '' || this.validateProfile.fullname === '' || this.validateProfile.user_gender === ''
      || this.validateProfile.user_email === '' || this.validateProfile.user_phone === '' || this.validateProfile.user_address === ''
      || this.validateProfile.address_partner_business === '' || this.validateProfile.partner_business_name === ''
      || this.validateProfile.partner_category_name === '' || this.validateProfile.partner_business_type_id === ''
      || this.validateProfile.partner_business_logo === '') {
        this.$refs['modal-validate-profile'].show()
      } else {
        this.fieldActionAddRekening = true
        if (this.editMode === true) {
          this.editMode = false
        }
      }
    },
    cancelAddRekening() {
      this.fieldActionAddRekening = false
      this.validateLength = ''
      this.ValidateAccountName = false
      this.fieldAddAccountNo = ''
      this.fieldAddAccountName = ''
      this.fieldAddBankName = ''
      this.isValidateAccountName = false
      this.checkValidBank = true
      this.messageSameNoBank = ''
      this.validateFieldAddBankName = false
      this.validateFieldAddAccountNo = false
    },
    changeFieldAddBank() {
      this.fieldAddAccountName = ''
      this.checkValidBank = true
      this.isValidateAccountName = false
      this.messageSameNoBank = ''
    },
    async getProfile() {
      this.phoneUser = this.profile.user_phone
      this.phoneNumber = this.profile.user_phone
      this.validateProfile = this.profile
      await this.$http_komship.post(`/v1/check-wa?phone_no=${this.phoneUser}`)
        .then(res => {
          this.messageErrorPhoneUser = false
        })
        .catch(error => {
          this.messageErrorPhoneUser = true
          if (error.response.data.code !== 1001) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: error,
                variant: 'danger',
              },
            })
          }
        })
    },
    closePopupSuccess() {
      this.$refs['success-add-rekening'].hide()
      this.fieldActionAddRekening = false
      this.fieldAddBankName = ''
      this.fieldAddAccountNo = ''
      this.fieldAddAccountName = ''
      this.isValidateAccountName = false
      this.checkValidBank = true
      this.getBank()
    },
    checkBank: _.debounce(function () {
      if (this.fieldAddBankName !== '' && this.fieldAddAccountNo !== '' && this.fieldAddAccountName !== '' && this.accountNameDB === true) {
        this.checkValidBank = false
        this.loadingSubmit = true
        if (this.loadingSubmit === true) {
          this.checkValidBank = true
        }
        const formData = new FormData()
        formData.append('bank_name', this.fieldAddBankName)
        formData.append('account_name', this.fieldAddAccountName)
        formData.append('account_no', this.fieldAddAccountNo)
        formData.append('user_id', this.$store.state?.auth?.userData.id)
        this.$http.post('/v1/bank/check', formData)
          .then(response => {
            this.loadingSubmit = false
            this.checkValidBank = true
            this.messageSameNoBank = ''
            this.messageSameNameBank = ''
            this.$bvModal.show('req-OTP-phone')
          }).catch(err => {
            if (err.response.data.code === 1001) {
              if (err.response.data.message === 'Nomor Rekening Sudah digunakan') {
                this.messageSameNoBank = err.response.data.message
                this.messageSameNameBank = ''
                this.checkValidBank = true
              }
              if (err.response.data.message === 'Nama telah digunakan sebelumnya') {
                this.messageSameNameBank = err.response.data.message
                this.messageSameNoBank = ''
                this.checkValidBank = true
              }
            }
            if (err.response.data.code === 1002) {
              this.messageSameNoBank = ''
              this.messageSameNameBank = ''
              this.checkValidBank = true
              this.banksDataMinus = err.response.data.data
              this.$refs['popup-check-rekening'].show()
            } else {
              this.loadingSubmit = false
              this.checkValidBank = false
            }
            this.loadingSubmit = false
            this.checkValidBank = false
          })
      }
    }),
    closeCheckRek() {
      this.$refs['popup-check-rekening'].hide()
    },
    submitAccount() {
      this.$bvModal.show('req-OTP-phone')
      this.$refs['popup-check-rekening'].hide()
    },
    formatNumber: value => (`${value}`).replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
    getAccount() {
      this.$refs.bankAccount.focus()
      if (this.fieldAddBankName !== '') {
        this.validateFieldAddBankName = false
        if (this.fieldAddAccountNo !== '') {
          this.validateFieldAddAccountNo = false
          if (this.fieldAddAccountNo.length >= 1 && this.fieldAddAccountNo.length < 5) {
            this.lenghtNoAccount = false
            this.validateLength = 'Minimal 5 angka ya, pastikan jenis bank sudah benar'
          } else {
            this.lenghtNoAccount = true
            this.validateLength = ''
            this.getAccountBank()
          }
        } else {
          this.validateFieldAddAccountNo = true
        }
      } else {
        this.validateFieldAddBankName = true
      }
    },
    getAccountBank() {
      this.ValidateAccountName = true
      this.$http.post('/v1/bank/check-bank-owner',
        {
          bank_name: this.fieldAddBankName,
          account_no: this.fieldAddAccountNo,
        }, {
          headers: { Authorization: `Bearer ${useJwt.getToken()}` },
        }).then(response => {
        this.isValidateAccountName = true
        if (response.data.data.account_name !== undefined) {
          this.ValidateAccountName = false
          this.accountNameDB = true
          this.fieldAddAccountName = response.data.data.account_name
          this.getValidateAccountName = true
          this.checkValidBank = false
        } else {
          this.ValidateAccountName = false
          this.checkValidBank = true
          this.getValidateAccountName = false
          this.fieldAddAccountName = ''
        }
      }).catch(err => {
        this.isValidateAccountName = true
        this.ValidateAccountName = false
        this.checkValidBank = true
        this.fieldAddAccountName = ''
        this.getValidateAccountName = false
      })
    },
    AccountBankNo(e) {
      this.fieldAddAccountNo = ''
      this.fieldAddAccountName = ''
      this.ValidateAccountName = false
      this.checkValidBank = true
      this.isValidateAccountName = false
      const dummyAccountBank = e.clipboardData.getData('text').replace(/\D/g, '')
      this.fieldAddAccountNo = dummyAccountBank
    },
    requestTypeSelected(value) {
      this.reasonCreateRekening = ''
      this.requestType = value
      const securityChange = secureLs.getItem('securityChangerekening')
      this.tokenPhoneVerify = btoa(`${securityChange.securityChange.token}%${this.purpose}`)
      this.$bvModal.show('confirm-otp-phone')
    },
    successVerifyOldPhone() {
      this.$refs['success-add-rekening'].show()
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
.validate-green {
  border: 1px solid #DCF3EB;
  background-color: #DCF3EB;
  width: 100%;
  border-radius: 8px;
}
.validate-red {
  border: 1px solid #FFF2E2;
  background-color: #FFF2E2;
  width: 100%;
  border-radius: 8px;
}
.message-account-name {
  font-size: 14px;
}
.cekRekening {
  width: 13rem;
}
@media screen and (max-width: 1200px) {
  .cekRekening {
    width: 14rem;
  }
}
@media screen and (max-width: 780px) {
  .cekRekening {
    width: 18rem;
  }
  .message-account-name {
    font-size: 12px;
  }
}
@media screen and (max-width: 520px) {
  .message-account-name {
    font-size: 10px;
  }
  .cekRekening {
    width: 100%;
    margin-top: 1rem;
    margin-left: 0px !important;
  }
  .icon-validate {
    width: 20px;
    height: 20px;
    align-self: center;
  }
}
[dir] .otp-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  [dir] .otp-input::-webkit-inner-spin-button,
  [dir] .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [dir] input.vue-pincode-input {
    box-shadow: none!important;
    border-bottom: 2px solid #FF6A3A!important;
    border-radius: 0px!important;
    color: black;
}
    [dir] input.vue-pincode-input:placeholder-shown  {
      box-shadow: none!important;
      border-bottom: 2px solid #828282!important;
      border-radius: 0px!important;
  }
  [dir] input.vue-pincode-input:focus {
    box-shadow: none!important;
    border-bottom: 2px solid #FF6A3A!important;
    border-radius: 0px!important;
}
  [dir] .wrapper__send__otp:hover {
    background: #FFECE9;
  }
  [dir] .wrapper__otp__wa {
    background: #C2C2C2;
  }
  [dir] .border__table__check__rek {
    border: 1px solid #E2E2E2;
  }
  .has-spinner {
  position: relative;
  }
  .spinner-border {
    position: absolute;
    left: 1%;
    top: 40%;
  }
</style>

<template>
  <b-modal
    id="max-chance-PIN"
    hide-footer
    hide-header
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    centered
  >
    <div class="text-black text-center mb-2">
      <BRow class="justify-content-center mb-1 pt-1">
        <img src="https://storage.googleapis.com/komerce/assets/svg/Icon-Error.svg">
      </BRow>
      <div class="mb-1 pt-1">
        <h2><b>Kamu sudah salah input PIN 3x</b></h2>
      </div>
      <div class="mb-1 pt-1">
        <p>Silakan coba lagi dalam 24 jam sejak terakhir salah input PIN</p>
      </div>
      <BButton
        block
        variant="primary"
        @click="forgotPIN()"
      >
        Lupa PIN
      </BButton>
    </div>
  </b-modal>
</template>
<script>
export default {
  methods: {
    forgotPIN() {
      const path = this.$route.name
      if (path === 'kompship-pin') {
        this.$bvModal.hide('max-chance-PIN')
        this.$bvModal.show('modal-forgot-pin')
      } else {
        this.$router.push({ name: 'kompship-pin' })
      }
    },
  },
}
</script>

<template>
  <BModal
    :id="id"
    hide-footer
    hide-header
    hide-header-close
    :no-close-on-backdrop="noCloseOnBackdrop"
    :no-close-on-esc="noCloseOnEsc"
    centered
    @hidden="resetCondition"
    @shown="onShown"
  >
    <b-form
      @submit.prevent="confirmation"
    >
      <b-col class="d-flex justify-content-center mt-2">
        <h4>
          <strong>{{ title }}</strong>
        </h4>
      </b-col>

      <b-col
        v-if="description !== ''"
        class="d-flex justify-content-center text-center mt-1"
      >
        <small>
          <strong>{{ description }}</strong>
        </small>
      </b-col>

      <b-col class="d-flex justify-content-center mt-2 mb-1">
        <PincodeInput
          :key="componentKey"
          v-model="PIN"
          placeholder=""
          :length="6"
          :secure="securePIN"
          :disabled="disabledInputOTP"
        />
      </b-col>

      <b-col class="d-flex justify-content-center">
        <b-button
          type="button"
          variant="flat-primary"
          class="btn-icon"
          @click="toggleVisibilityPin"
        >
          {{ securePIN ? 'Tampilkan' : 'Sembunyikan' }}
        </b-button>
      </b-col>

      <b-col
        v-if="messageError !== '' || PIN === '000000'"
        class="justify-content-center text-center my-1 content-center"
      >
        <div
          v-if="messageError !== 'PIN harus sama dengan PIN sebelumnya' || PIN === '000000'"
          class="text-danger"
        >
          PIN tidak valid
        </div>
        <small class="text-danger d-inline-block w-75">
          {{ messageError }}
        </small>
      </b-col>

      <b-col
        v-if="id === 'modal-verify-pin'"
        class="justify-content-center text-center my-1"
      >
        <b-button
          type="button"
          variant="link"
          class="text-primary text-16-normal"
          @click="forgotPIN()"
        >
          <b>Lupa PIN</b>
        </b-button>
      </b-col>

      <b-row
        :class="labelCancelButton !== '' ? 'justify-content-between' : 'justify-content-center'"
        class="d-flex mt-1 pb-2"
      >
        <b-col
          v-if="labelCancelButton !== ''"
          cols="6"
        >
          <b-button
            type="button"
            variant="outline-primary"
            block
            @click="$emit('on-cancel-button'), resetCondition()"
          >
            {{ labelCancelButton }}
          </b-button>
        </b-col>
        <b-col>
          <b-button
            type="submit"
            variant="primary"
            block
            :disabled="PIN.length < 6 || loading || messageError !== '' || PIN === '000000'"
          >
            <b-spinner
              v-if="loading"
              small
              variant="light"
            />
            {{ labelConfirmButton }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </BModal>
</template>

<script>
import PincodeInput from 'vue-pincode-input'
import { removeLocalStorageLogout } from '@/auth/utils'
import secureLs from '@/libs/secureLs'

export default {
  components: {
    PincodeInput,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    partnerId: {
      type: Number,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
      required: false,
    },
    labelConfirmButton: {
      type: String,
      default: 'Konfirmasi',
      required: false,
    },
    labelCancelButton: {
      type: String,
      default: '',
      required: false,
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false,
      required: false,
    },
    noCloseOnEsc: {
      type: Boolean,
      default: false,
      required: false,
    },
    previousPin: {
      type: String,
      default: '',
      required: false,
    },
    oldPin: {
      type: String,
      default: '',
      required: false,
    },
    securityPin: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      PIN: '',
      securePIN: true,
      messageError: '',
      componentKey: null,
      disabledInputOTP: false,
      removeLocalStorageLogout,
      queryParams: this.$route.query,
    }
  },
  watch: {
    PIN() {
      if (this.id !== 'modal-verify-pin') {
        if (this.previousPin.length === this.PIN.length && this.previousPin !== this.PIN) {
          this.messageError = 'PIN harus sama dengan PIN sebelumnya'
        } else {
          this.messageError = ''
        }
      } else {
        this.messageError = ''
      }
    },
  },
  methods: {
    toggleVisibilityPin() {
      this.securePIN = !this.securePIN
      this.componentKey = Date.now()
    },
    resetCondition() {
      this.PIN = ''
      this.messageError = ''
      this.loading = false
      this.securePIN = true
      this.$bvModal.hide(this.id)
    },
    confirmation() {
      if (this.PIN.length < 6 || this.loading || this.messageError !== '' || this.PIN === '000000') return
      this.loading = true
      if (this.id === 'modal-verify-pin') {
        this.verifyPIN()
      } else if (this.id === 'create-pin' && this.PIN !== '000000') {
        this.$emit('on-confirm-button', this.PIN)
        this.resetCondition()
      } else if (this.id === 'confirm-create-pin') {
        this.confirmCreatePin()
      } else if (this.id === 'change-pin' && this.PIN !== '000000') {
        this.$emit('on-confirm-button', this.PIN)
        this.resetCondition()
      } else if (this.id === 'confirm-change-pin') {
        this.submitChangePin()
      } else if (this.id === 'forgot-pin' && this.PIN !== '000000') {
        this.$emit('on-confirm-button', this.PIN)
        this.resetCondition()
      } else if (this.id === 'confirm-forgot-pin') {
        this.submitChangeNewPin()
      }
    },
    confirmCreatePin() {
      if (this.PIN === this.previousPin && this.PIN.length === 6 && this.previousPin.length === 6) {
        this.$http_new
          .post('/auth/api/v1/user/setting/pin/store', {
            pin: this.PIN,
          })
          .then(() => {
            this.$emit('on-confirm-button')
            this.resetCondition()
            if (this.queryParams.komads) {
              this.$router.push('/iklan')
            }
          }).catch(err => {
            this.loading = false
            if (err.response.data.data === 'pin already stored, unable to store') {
              this.messageError = 'PIN sudah ada sebelumnya, silahkan klik "lupa PIN ?"'
            } else {
              this.messageError = 'Gagal konfirmasi buat PIN'
            }
          })
      } else {
        this.loading = false
        this.$toast_error({ message: 'Gagal buat PIN, silahkan coba kembali' })
        this.messageError = 'Gagal buat PIN, silahkan coba kembali'
      }
    },
    submitChangePin() {
      if (this.PIN === this.previousPin && this.PIN.length === 6 && this.previousPin.length === 6) {
        const token = secureLs.getItem('verify')
        const body = {
          pin: this.PIN,
          old_pin: this.oldPin,
          token: btoa(token.data.usable_token),
        }

        this.$http_new
          .post('/auth/api/v1/user/setting/pin/update', body)
          .then(response => {
            const { data } = response
            if (data.meta.status === 'success') {
              this.$emit('on-confirm-button')
              this.resetCondition()
            } else {
              this.loading = false
            }
          }).catch(() => {
            this.loading = false
            this.$toast_error({ message: 'Gagal ganti pin, silahkan coba lagi' })
          })
      } else {
        this.loading = false
        this.$toast_error({ message: 'Gagal ganti PIN, silahkan coba kembali' })
      }
    },
    submitChangeNewPin() {
      if (this.PIN.length === 6) {
        const token = btoa(`${this.securityPin.securityChange.token}%pin`)
        this.$http_new.post('/auth/api/v1/otp/secured/user/update-pin', {
          new_pin: this.PIN,
          token,
        }).then(response => {
          this.$emit('on-confirm-button')
          this.resetCondition()
        }).catch(err => {
          this.loading = false
          this.$toast_error({ message: err })
        })
      } else {
        this.loading = false
        this.$toast_error({ message: 'Gagal update PIN, silahkan coba kembali' })
      }
    },
    verifyPIN() {
      if (this.PIN.length === 6) {
        this.$http_new.post('/auth/api/v1/user/secured/verify-pin', {
          pin: this.PIN,
        }).then(response => {
          this.loading = false
          const { data } = response.data
          const combineData = {
            id: this.partnerId,
            data,
          }
          secureLs.setItem('verify', JSON.stringify(combineData))
          if (data.attempt_left === 0) {
            this.messageError = 'PIN salah, kesempatan input PIN telah habis. Akun kamu akan terlogout secara otomatis.'
            this.removeLocalStorageLogout()
            window.location.replace('/')
          } else if (!data.is_valid) {
            this.messageError = `Kamu memiliki ${data.attempt_left} percobaan lagi sebelum akun anda logout secara otomatis`
          } else if (data.is_valid) {
            this.$emit('on-confirm-button', this.PIN)
            this.resetCondition()
          }
        }).catch(err => {
          this.loading = false
          this.resetCondition()
          this.$bvModal.show('max-chance-PIN')
        })
      }
    },
    forgotPIN() {
      const path = this.$route.name
      if (path === 'kompship-pin') {
        this.$bvModal.hide(this.id)
        this.$bvModal.show('modal-forgot-pin')
      } else {
        this.$router.push({ name: 'kompship-pin' })
      }
    },
    onShown() {
      const otherActiveEl = document.activeElement
      const pinEl = document.querySelector('.vue-pincode-input-wrapper')
      if (otherActiveEl !== pinEl.firstChild) otherActiveEl.blur()
      pinEl.firstChild.setAttribute('tabindex', '0')
      pinEl.firstChild.focus()
    },
  },
}
</script>

<style lang="scss">
[dir] input.vue-pincode-input {
    box-shadow: none!important;
    border-bottom: 2px solid black!important;
    border-radius: 0px!important;
    color: black;
}

[dir] .otp-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  [dir] .otp-input::-webkit-inner-spin-button,
  [dir] .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  [dir] input.vue-pincode-input {
    box-shadow: none!important;
    border-bottom: 2px solid #FF6A3A!important;
    border-radius: 0px!important;
    color: black;
}
  [dir] input.vue-pincode-input:placeholder-shown  {
      box-shadow: none!important;
      border-bottom: 2px solid #828282!important;
      border-radius: 0px!important;
  }
  [dir] input.vue-pincode-input:focus {
    box-shadow: none!important;
    border-bottom: 2px solid #FF6A3A!important;
    border-radius: 0px!important;
}
</style>

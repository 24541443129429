<template>
  <BModal
    :id="id"
    hide-footer
    hide-header
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    centered
  >
    <div class="flex flex-col justify-center items-center text-center gap-6 px-[20px] py-[50px]">
      <img
        :src="icon"
        alt="Komerce"
        width="100"
      >

      <div class="flex flex-col gap-[4px]">
        <h4 class="font-semibold text-[22px] text-black mb-1">
          {{ title }}
        </h4>

        <slot
          v-if="$slots.description"
          name="description"
        />
        <p
          v-else
          class="text-[#222222]"
        >{{ description }}</p>
      </div>

      <div
        class="flex justify-center items-center gap-[4px] w-full"
      >
        <BButton
          v-if="labelCancelButton !== ''"
          variant="outline-primary"
          class="flex-[45%] rounded-[8px!important]"
          @click="$emit('on-cancel-button', $event)"
        >
          {{ labelCancelButton }}
        </BButton>

        <BButton
          v-if="labelConfirmButton !== ''"
          variant="primary"
          class="flex-[45%] rounded-[8px!important]"
          @click="$emit('on-confirm-button', $event)"
        >
          {{ labelConfirmButton }}
        </BButton>

      </div>
    </div>
  </BModal>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'https://storage.googleapis.com/komerce/core/icon-popup-warning.png',
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
      required: false,
    },
    labelConfirmButton: {
      type: String,
      default: 'Konfirmasi',
      required: false,
    },
    labelCancelButton: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {}
  },
}
</script>
